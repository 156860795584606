import Image from 'global/Image';
import Link from 'next/link';
import { ImgHTMLAttributes } from 'react';
import Username from '../Username';
import { Gradient, ImgWrapper, LinkWrapper, UsernameWrapper } from './styled';

export interface ImageItemProps extends ImgHTMLAttributes<HTMLImageElement> {
  username?: string;
  userImgSrc?: string;
  href?: string;
}

const ImageItem = ({
  className,
  username = '',
  src = '',
  userImgSrc = '',
  href = '',
  ...props
}: ImageItemProps) => {
  if (!src) return null;
  return (
    <LinkWrapper href={href} target="_blank" rel="noopener noreferrer">
      <ImgWrapper className={className}>
        <Gradient>
          <UsernameWrapper>
            <Username username={username} userImgSrc={userImgSrc} />
          </UsernameWrapper>
        </Gradient>
        <Image src={src} {...props} />
      </ImgWrapper>
    </LinkWrapper>
  );
};

export default ImageItem;
